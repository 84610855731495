<template>
  <v-container fluid fill-height>
    <v-row>
      <v-col>
        <v-expand-transition>
          <v-alert color="info" class="white--text" v-show="isComplete">
            <v-icon class="mr-2 white--text" large> mdi-cloud-check </v-icon>
            Successfully Sent Approval, you may close this page now.
          </v-alert>
        </v-expand-transition>
        <v-card elevation="10">
          <v-card-title> Create Approval </v-card-title>
          <v-card-title class="primary--text" v-if="ticket.id">
            {{ ticket.subject }}
          </v-card-title>
          <v-expand-transition>
            <v-card-text v-if="!inError">
              <trev-approval-add
                :showCancel="false"
                :isDisabled="isComplete"
                v-if="ticket.id"
                :companyId="ticket.parentCompanyId"
                :clearOnSave="false"
                @updated="addedApproval"
                :showSave="!isComplete"
              >
              </trev-approval-add>
              <v-skeleton-loader
                v-else
                type="article,article actions"
                width="90vh"
              >
              </v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
              <v-alert color="danger">
                {{errorMessage}}
              </v-alert>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrevApprovalAdd from "../../../components/approvals/trev-approval-add.vue";
export default {
  components: {
    TrevApprovalAdd,
  },
  created() {
    setTimeout(() => {
      this.getTicket();
    }, 400);
  },
  methods: {
    async addedApproval(approval) {
      this.isComplete = true;
      //comment generation for ticket
      var comment =
        "<h2>Send Approval</h2><p>To: " +
        approval.approvers
          .map((x) => x.firstName + " " + x.lastName)
          .join(", ") +
        "</p><br/><p>" +
        approval.title +
        "</p><br/>" +
        approval.content;

      //associate ticket with approval
      approval.attachedToTicketId = this.ticket.id;
      var appUpdatePromise = this.$courier.Approvals.updateById(
        approval.id,
        approval
      );
      var ticketCommentAddPromise = this.$courier.ZohoTicketsRaw.withId(
        this.ticket.zohoId
      ).Comments.add(comment, false);

      var rawTicket = await this.$courier.ZohoTicketsRaw.getById(
        this.ticket.zohoId
      );
      rawTicket.status = "Open";
      var ticketUpdateToOpenPromise = this.$courier.ZohoTicketsRaw.updateById(
        this.ticket.zohoId,
        rawTicket
      );

      var arrOfPromises = [
        appUpdatePromise,
        ticketCommentAddPromise,
        ticketUpdateToOpenPromise,
      ];
      await Promise.all(arrOfPromises);
    },
    async getTicket() {
      if (this.$route.query.ticketId) {
        this.ticket = await this.$courier.ZohoTickets.getByZohoId(
          this.$route.query.ticketId
        );
      }

      if(!this.ticket.id){
        //show some kind of error
        this.inError = true;
        this.errorMessage = 'No Ticket Found for id: ' + this.$route.query.ticketId;
      }
    },
  },
  data() {
    return {
      ticket: {},
      isComplete: false,
      inError: false,
      errorMessage: ''
    };
  },
};
</script>

<style>
</style>